import Types from '../types';

export const initialState = {
  myAds: [],
  ads: {},
  selectedAds: [],
  profileAds: {},
  adConfigs: {},
  drafts: {},
  lastActiveStep: 1,
  profileId: '',
  audience: [],
  draftId: '',
  category: '',
  category_id: null,
  labels: [],
  seekLabels: [],
  stepsConfigs: null,
  needToUpdateOnlineAd: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.SET_MY_ADS:
      return { ...state, ads: action.payload };
    case Types.NEED_TO_UPDATE_ONLINE_AD:
      return { ...state, needToUpdateOnlineAd: action.payload };

    case Types.SET_MY_ADS_AFTER_CHANGE_PAGE:
      const stateClone = { ...state };
      stateClone.ads.listing[action.payload.type].data = [
        ...state.ads.listing[action.payload.type].data,
        ...action.payload.data.data,
      ];
      return stateClone;

    case Types.RESET_ADS:
      return {
        ...state,
        lastActiveStep: 2,
        stepsConfigs: {
          stepTwo: {
            ...state.stepsConfigs.stepTwo,
          },
        },
      };
    case Types.SET_MY_AD:
      return {
        ...state,
        ads: {
          ...state.ads,
          listing: {
            ...state.ads.listing,
            [action.payload.type]: action.payload.data,
          },
        },
      };
    case Types.SAVE_FILTERED_PROFILE_AD:
      return {
        ...state,
        ads: {
          ...state.ads,
          listing: {
            ...state.ads.listing,
            [action.payload.type]: {
              ...state.ads.listing[action.payload.type],
              data: action.payload.data,
            },
          },
        },
      };
    case Types.SET_MAIN_VALUES:
      return {
        ...state,
        draftId: action.payload.draftId,
        profileId: action.payload.profileId,
        audience: action.payload.audience,
      };
    case Types.SAVE_CATEGORIES:
      return {
        ...state,
        stepsConfigs: { ...state.stepsConfigs, stepTwo: { ...action.payload } },
      };
    case Types.SET_AD_CONFIGS:
      return { ...state, adConfigs: action.payload };
    case Types.RESET_SEEK_LABELS:
      return { ...state, seekLabels: [] };
    case Types.SET_CATEGORIES_WITH_SECTIONS:
      return { ...state, categoriesWithSections: action.payload };
    case Types.SAVE_DATA_STEP_THREE:
      return {
        ...state,
        lastActiveStep: state.lastActiveStep > 3 ? state.lastActiveStep : 4,
        stepsConfigs: {
          ...state.stepsConfigs,
          stepThree: { ...action.payload },
        },
      };
    case Types.SET_AD_DRAFTS:
      const newState = { ...state };
      if (action.payload.categoriesList) {
        newState.stepsConfigs = {
          stepTwo: { ...action.payload.categoriesList },
        };
      }

      if (action.payload.stepThree) {
        newState.lastActiveStep =
          state.lastActiveStep > 3 ? state.lastActiveStep : 4;
        newState.stepsConfigs.stepThree = { ...action.payload.stepThree };
      }
      return {
        ...newState,
        draftId: action.payload._id,
        profileId: action.payload._meta.profile_ids[0],
        audience: action.payload.audience,
        category: action.payload.category
          ? action.payload.category
          : state.category,
        labels: action.payload.labels,
        category_id: action.payload.category_id,
        type: action.payload.type,
        reference_id: action.payload._meta.reference_id,
      };
    case Types.SAVE_CATEGORY:
      // return { ...state, category: action.payload }
      return { ...state, ...action.payload };
    case Types.SAVE_STEP_FIVE_VALUES:
      // return { ...state, category: action.payload }
      return {
        ...state,
        stepsConfigs: { ...state.stepsConfigs, stepFive: action.payload },
      };

    case Types.DELETE_IMAG_FROM_STEP_FOUR:
      // return { ...state, category: action.payload }
      return {
        ...state,
        stepsConfigs: {
          ...state.stepsConfigs,
          stepFour: {
            ...state.stepsConfigs.stepFour,
            checkedImages: action.images,
          },
        },
      };
    case Types.SAVE_STEP_FOUR_CHECKED_IMAGES:
      // return { ...state, category: action.payload }
      const cloneState = { ...state };
      if (cloneState.stepsConfigs.hasOwnProperty('stepFour')) {
        cloneState.stepsConfigs.stepFour.checkedImages = action.payload;
      } else {
        cloneState.stepsConfigs.stepFour = { checkedImages: action.payload };
      }
      return { ...cloneState };
    case Types.SAVE_IMAGES_STEP_FOUR:
      return {
        ...state,
        stepsConfigs: {
          ...state.stepsConfigs,
          stepFour: { checkedImages: action.payload.imagesList },
        },
      };
    case Types.RESET_PROFILE_ADD_FROM_STATE:
      return {
        ...state,
        drafts: {},
        lastActiveStep: 1,
        profileId: '',
        audience: [],
        draftId: '',
        labels: [],
        category: '',
        stepsConfigs: null,
      };
    default:
      return state;
  }
};

export default reducer;
