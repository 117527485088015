import { createSelector } from 'reselect';

import { _adsState, _authState } from './selectors';

const adsSelector = () => createSelector([_adsState], items => items);

export const getAdCategoryId = store => _adsState(store).category_id;

export const getAdStore = store => _adsState(store);
export const getAds = store => getAdStore(store).ads;

const getCategoriesWithSections = store => {
  const { categories } = _adsState(store).categoriesWithSections || {};
  const result = Object.values(categories || {}).reduce((acc, category) => {
    acc[category.slug] = category.sections;
    return acc;
  }, {});

  return result;
};

const getActiveTabAds = createSelector(
  [_adsState],
  items => status => items[status],
);

const getProfileAds = id =>
  createSelector([_adsState], items => items.profileAds[id] || []);

const getAdCategoryFields = categoryType =>
  createSelector(
    [_adsState],
    items => items.adConfigs[`profile_ad_${categoryType}_fields`] || {},
  );

const getCategoryLabels = createSelector([_adsState], items => items.labels);

const getCategorySeekLabels = createSelector(
  [_adsState],
  items => items.seekLabels,
);

const getAdConfigs = () =>
  createSelector([_adsState], items => items.adConfigs || {});

const getDrafts = () => createSelector([_adsState], items => items.drafts);

const getReferenceId = () =>
  createSelector([_adsState], items => items.reference_id);

const getDraft = draftId =>
  createSelector([_adsState], items => items.drafts[draftId]);

const getLastActiveStep = () =>
  createSelector([_adsState], items => {
    let step = 1;
    ['profileId', 'category'].forEach(el => {
      if (items[el]) {
        step += 1;
      }
    });

    if (
      items.stepsConfigs &&
      items.stepsConfigs.hasOwnProperty('stepThree') &&
      items.stepsConfigs.stepThree.hasOwnProperty('title')
    ) {
      step = 4;

      if (items.stepsConfigs.hasOwnProperty('stepFour')) {
        step = 5;
      }
    }

    return step;
  });

export const getAdLastActiveStep = store => _adsState(store)?.lastActiveStep;

const getRequiredPhotoscount = () =>
  createSelector([_adsState], items => {
    if (items.stepsConfigs) {
      const findIndex = items.stepsConfigs.stepTwo.categories.findIndex(
        cat => cat.name === items.category,
      );
      return findIndex > -1
        ? items.stepsConfigs.stepTwo.categories[findIndex].min_photo_count
        : 0;
    }
  });

const getStepConfigs = step =>
  createSelector([_adsState], items =>
    items.stepsConfigs ? items.stepsConfigs[step] : {},
  );

export const getStepTwoConfigs = store =>
  _adsState(store).stepsConfigs?.stepTwo;

const categoryServices = categoryName =>
  createSelector(
    [_adsState],
    items =>
      items.adConfigs?.category?.[categoryName]?.services?.map(el => ({
        title: el.name,
        value: el.value,
        checked: false,
      })) || [],
  );

const getWebSite = () =>
  createSelector(
    [_adsState],
    items =>
      !!(
        items.stepsConfigs.stepThree?.websites &&
        items.stepsConfigs.stepThree?.websites.length
      ),
  );

const getMainValues = () =>
  createSelector(
    [_adsState],
    items =>
      ({
        profileId: items.profileId,
        draftId: items.draftId,
        audaudience: items.audience,
      } || {}),
  );

const getCategoryAndType = () =>
  createSelector([_adsState], items => ({
    type: items.type,
    category: items.category,
    category_id: items.category_id,
  }));

const getCategoryInformation = id =>
  createSelector([_adsState], items =>
    items.stepsConfigs.stepTwo.categories?.find(cat => cat.id === id),
  );

const adCategories = createSelector([_adsState], items => {
  if (items.categoriesWithSections?.categories) {
    const { categories } = items.categoriesWithSections;
    return Object.keys(categories).map(item => ({
      id: Number(item),
      title: categories[item].name,
      pathname: categories[item].name,
      value: item,
      slug: categories[item].slug,
      value_nl: categories[item].slug,
      labels: categories[item].sections.map(label => ({
        title: label.name,
        id: label.id,
        value_nl: label.slug,
        slug: label.slug,
        pathname: label.name,
        parentId: item,
        parentSlug: categories[item].slug,
      })),
      ...(categories[item].services
        ? {
            services: categories[item].services.map(service => ({
              title: service.name,
              value: service.value,
            })),
          }
        : {}),
    }));
  }
  return [];
});
const getProfileCategories = (accountType, draftId) =>
  createSelector([_authState, _adsState], (authItems, adItems) => {
    const { accountProfiles } = authItems;
    const { adConfigs, drafts } = adItems;
    if (accountType === 'single') {
      const { gender } = accountProfiles[0];
      return adConfigs[gender];
    }
    if (accountType === 'couple') {
      const [profileOne, profileTwo] = accountProfiles;
      return (
        adConfigs[`${profileOne.gender}_with_${profileTwo.gender}`] ||
        adConfigs[`${profileTwo.gender}_with_${profileOne.gender}`]
      );
    }
    if (accountType === 'company') {
      const selectedCompanyProfileIdCreateAd =
        drafts[draftId]._meta.profile_ids[0];
      const companyProfile = accountProfiles.find(
        e => e._id === selectedCompanyProfileIdCreateAd,
      );
      if (companyProfile) {
        const {
          gender,
          _meta: { type },
        } = companyProfile;
        if (gender) {
          return adConfigs[gender];
        }
        if (type) {
          return adConfigs[type];
        }
      }
    }
    return {};
  });

export const getSelectedCategory = (store, slug = []) => {
  const categories = adCategories(store);

  const selectedCategory = categories.find(
    ({ value_nl }) => slug[0] === value_nl,
  );

  return selectedCategory;
};

export const getSelectedSubCategory = (store, slug) => {
  const categories = getSelectedCategory(store, slug);

  const subCategory = categories?.labels?.find(({ value_nl }) =>
    slug.includes(value_nl),
  );

  return subCategory;
};

export {
  adsSelector,
  getActiveTabAds,
  getCategoryInformation,
  getWebSite,
  getMainValues,
  getProfileAds,
  getReferenceId,
  getAdConfigs,
  getStepConfigs,
  getAdCategoryFields,
  adCategories,
  getCategoryAndType,
  getProfileCategories,
  getLastActiveStep,
  getRequiredPhotoscount,
  getCategoryLabels,
  getCategorySeekLabels,
  getDrafts,
  getCategoriesWithSections,
  categoryServices,
  getDraft,
};
