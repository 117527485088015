export const necessaryCookieInfo = {
  _ga: {
    placedBy: 'Google Analytics',
    expiresAfter: '2 years',
    description: 'ga_cookie_description',
  },
  ch: {
    placedBy: 'Sexjobs.nl',
    expiresAfter: '1 years',
    description: 'ch_cookie_description',
  },
  _cfduid: {
    placedBy: 'Sexjobs.nl',
    expiresAfter: '1 month',
    description: 'cfduid_cookie_description',
  },
  token: {
    placedBy: 'Sexjobs.nl',
    expiresAfter: '7 days',
    description: 'token_cookie_description',
  },
};

export const functionalCookieInfo = {
  lang: {
    placedBy: 'Sexjobs.nl',
    expiresAfter: '1 month',
    description: 'lang_cookie_description',
  },
  location: {
    placedBy: 'Sexjobs.nl',
    expiresAfter: '7 days',
    description: 'location_cookie_description',
  },
  sugar_preferences: {
    placedBy: 'Sexjobs.nl',
    expiresAfter: '1 year',
    description: 'sugar_preferences_cookie_description',
  },
};

export const marketingCookieInfo = {
  banner: {
    placedBy: 'Sexjobs.nl',
    expiresAfter: '1 month',
    description: 'banner_cookie_description',
  },
};

export const stepsTranslations = {
  1: {
    subTitle: 'registration_permissions_popup_subtitle_1',
    content: 'registration_permissions_popup_content',
    name: 'termsAndConditions',
    togglePermissions: 'registration_permissions_toggle_text',
    acceptButtonText: 'registration_permissions_button_next_text',
  },
  2: {
    subTitle: 'registration_permissions_popup_subtitle_2',
    content: 'registration_permissions_personal_information_popup_content',
    name: 'personalData',
    togglePermissions:
      'registration_permissions_personal_information_toggle_text',
    acceptButtonText: 'registration_permissions_button_done_text',
    backButtonText: 'registration_permissions_button_previous_text',
  },
};
