import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

const DEFAULT_API_URL = publicRuntimeConfig.API_BASE_URL;
const WS_URL = publicRuntimeConfig.WEB_SOCKET_URL;
const BASE_HOST = publicRuntimeConfig.BASE_HOST;
const LOG_LEVEL = publicRuntimeConfig.LOG_LEVEL;

export const ENV = {
  baseUrl:
    process.env.API_URL || DEFAULT_API_URL || 'https://dev-api.sexjobs.nl/v1',
  wsUrl: process.env.WS_URL || WS_URL || 'https://dev-ws.sexjobs.nl/interior',
  baseHost: process.env.BASE_HOST || BASE_HOST || 'https://www.dev.sexjobs.nl',
  logLevel: process.env.LOG_LEVEL || LOG_LEVEL || 'info',
};

export const SHOW_ADS = 'Show Ads';
export const DONT_SHOW_ADS = "Don't show Ads";
export const ABOVE_CONTENT = 'Above Content';
export const BELOW_CONTENT = 'Below Content';

export const BUMP_PRICE = 1.8;

export const GLOBAL_CONSTANTS = {
  discreteColor: '#05736a',

  categories: [
    { title: 'All categories', value: 'all' },
    { title: 'Events', value: 'events', id: 1 },
    { title: 'Vacancies', value: 'vacancies', id: 2 },
    { title: 'Ladies of pleasure', value: 'ladies_of_pleasure', id: 3 },
    { title: 'Erotic massage', value: 'erotic_massage', id: 4 },
    { title: 'SM', value: 'sm', id: 5 },
    { title: 'Cam & phonesex', value: 'cam_phonesex', id: 6 },
    { title: 'Men', value: 'men', id: 7 },
    { title: 'Payment in kind', value: 'payment_in_kind', id: 8 },
    { title: 'Free sex contact', value: 'free_sex_contact', id: 9 },
    { title: 'Sex items', value: 'sex_items', id: 10 },
    { title: 'Gay & bi', value: 'gay_bi', id: 11 },
    { title: 'Shemale', value: 'shemale', id: 12 },
    { title: 'Misc', value: 'misc', id: 13 },
    { title: 'Sugar dating', value: 'sugar_dating', id: 14 },
  ],

  week: [
    { title: 'Monday', key: 'monday', weekday: 1 },
    { title: 'Tuesday', key: 'tuesday', weekday: 2 },
    { title: 'Wednesday', key: 'wednesday', weekday: 3 },
    { title: 'Thursday', key: 'thursday', weekday: 4 },
    { title: 'Friday', key: 'friday', weekday: 5 },
    { title: 'Saturday', key: 'saturday', weekday: 6 },
    { title: 'Sunday', key: 'sunday', weekday: 0 },
  ],

  adsImagesMaxCount: 40,
  draftMaxStep: 5,
};

export const PAGE_NAMES = [
  'login',
  'register',
  'reset-password',
  'verification',
];

export const UI = {
  toastTypes: ['info', 'warning', 'error', 'success'],
  button: {
    types: ['cta', 'default', 'primary', 'alt', 'white-bg', 'alert'],
  },
};

export const BREAKPOINTS = {
  keys: {
    xsss: 400,
    xss: 480,
    xs: 576,
    sm: 767,
    md: 991,
    lg: 1200,
    xl: 1920,
  },
};

export const DEVICE_CHECKING = {
  mobile: 767,
  tablet: [768, 991],
  desktop: 992,
};

export const VALIDATORS = {
  WEBSITE_PATTERN:
    /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,

  EMAIL_PATTERN: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,6}$/i,
  NAME_PATTERN: /^[\\-_a-z0-9\\s]+$/i,
  UPPERCASE_LOWERCASE_PATTERN: /([a-z].*[A-Z])|([A-Z].*[a-z])/,
  PHONE_INSTRUCTION_OTHER: /^[\\-_a-z0-9\\s]+$/,
  PHONE_NUMBER_PATTERN: /^[0-9]\\d*$/,
  DIGIT_PATTERN: /\d+/i,
  SYMBOL_PATTERN: /^(?=.*[!@#\\$%\\^&\\*\\-\\_\\?])/i,
  PASSWORD_VALIDATOR_PATTERN:
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*\\-\\_\\?])(?=.{8,20})/,
};

export const STATUSES = {
  offline: {
    color: '#989898',
    text: 'Offline',
  },
  online: {
    color: '#3DCB4D',
    text: 'Online',
  },
  concepten: {
    color: '#989898',
    text: 'Expired',
  },
  3: {
    color: '#F89E04',
    text: 'Waiting for moderation',
  },
  4: {
    color: '#989898',
    text: 'Passed event',
  },
};

export const languages = [
  {
    key: 'nl',
    value: 'netherlands',
    title: 'languages_dutch',
    imgPath: '/images/flags/netherlands.jpg',
  },
  {
    key: 'en',
    value: 'english',
    title: 'languages_english',
    imgPath: '/images/flags/england.png',
  },
];

export const CREATE_AD_PROGRESS_STEPS = [
  { title: 'create_ad_step_1_title', key: 1 },
  { title: 'create_ad_step_2_title', key: 2 },
  { title: 'create_ad_step_3_title', key: 3 },
  { title: 'create_ad_step_4_title', key: 4 },
  { title: 'create_ad_step_5_title', key: 6 },
];

export const ADVERTISEMENTS = {
  periodCountOptions: Array(30)
    .fill(0)
    .map((_, index) => ({
      title: (index + 1).toString(),
      value: index + 1,
    })),
  periodIntervalOptions: [
    { title: 'date_picker_day', value: 1 },
    { title: 'date_picker_week', value: 2 },
    { title: 'date_picker_month', value: 3 },
  ],
};

export const COUNTRY_PHONES = [
  {
    key: 'nl',
    phone_country_code: 31,
    phoneCode: '(+3\\1) 99999999999',
    validationLength: { min: 15, max: 16 },
    title: 'languages_dutch',
    value: 'nl',
    flagSrc: '/images/flags/nl.png',
  },
  {
    key: 'be',
    phone_country_code: 32,
    phoneCode: '(+3\\2) 9999 99 99 99',
    validationLength: { min: 6, max: 19 },
    title: 'languages_belgium',
    value: 'be',
    flagSrc: '/images/flags/be.png',
  },
];

export const GENDER_TYPES = [
  { title: 'Male', value: 'male' },
  { title: 'Female', value: 'female' },
];

export const DISTANCES = [
  { title: 'distances_all', value: 'all' },
  { title: '<5 KM', value: '5' },
  { title: '<10 KM', value: '10' },
  { title: '<15 KM', value: '15' },
  { title: '<25 KM', value: '25' },
  { title: '<50 KM', value: '50' },
];

export const SEARCH_MIN_LENGTH = 3;

export const NEARBY = 'nearby';

export const DEFAULT_DISTANCE = '15';

export const ALL_DISTANCES = 'all';

export const TYPE_ACCOUNT_NOTIFY = {
  single: {
    title: 'single_account_title',
    description: 'single_account_description',
  },
  couple: {
    title: 'couple_account_title',
    description: 'couple_account_description',
  },
  company: {
    title: 'company_account_title',
    description: 'company_account_description',
  },
};

export const MESSENGER = {
  threadsLimit: 15,
  threadMSGsLimit: 15,
};

export const NOTIFICATIONS = {
  countLimit: {
    popup: 6,
    page: 12,
  },
};

export const UNDO_DELAYS = {
  deleteConversation: 5000,
  reportConversation: 5000,
};

export const MEDIA = {
  storagePath: publicRuntimeConfig.IMAGE_BASE_URL || 'https://dev-i.sexjobs.nl',
  uploadImage: {
    base: {
      h: 800,
      w: 800,
    },
  },
};

export const COMPANY_COUNTRIES_KEYS = ['nl', 'be'];

export const requiredFields = [
  'city',
  'street',
  'house_number',
  'postal_code_digits',
  'postal_code_letters',
];

export const WEBSITE_LIMIT = 256;

export const LOCATION_PREFIX = '[LOCATION]';

export const CONSOLE_METHODS_BY_LEVEL = {
  info: ['info', 'warn', 'error', 'fatal', 'log'],
  warn: ['warn', 'error', 'fatal', 'log'],
  error: ['error', 'fatal', 'log'],
  fatal: ['log'],
};
