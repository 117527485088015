import cookie from 'js-cookie';
import CookieParser from 'cookie-parse';
import { formatISO } from 'date-fns';

import getImageMeta from 'utils/getImageMeta';
import checkIOSVersion from 'utils/checkIOSVersion';
import { getFromStorage, removeFromStorage } from 'utils/persist';

import { MEDIA } from './constants';

const availableCookiesGroup = {
  necessary: ['token', 'ch', '_ga', '_cfduid'],
  functional: ['lang', 'location', 'sugar_preferences'],
  marketing: ['banner'],
};

const getApprovedCookie = key => {
  const acceptedCookies = getFromStorage('acceptedCookies');

  if (acceptedCookies) {
    const cookiesGroupMapping = Object.entries(acceptedCookies);

    for (let i = 0; i < cookiesGroupMapping.length; i++) {
      const [cookieGroup, cookieValue] = cookiesGroupMapping[i];

      if (cookieValue && availableCookiesGroup[cookieGroup].includes(key)) {
        return true;
      }
    }
  }

  return false;
};

const UiGenerateMargin = (marginProp, directionProp) => {
  let margin;
  let direction;
  const marginDetect = () =>
    marginProp.reduce((acc, item) => {
      acc += `${item}px `;
      return acc;
    }, '');
  if (marginProp.constructor === Array || !Number.isNaN(marginProp)) {
    margin =
      typeof marginProp === 'number'
        ? `${marginProp}px`
        : marginDetect() || '0px';
  } else margin = 0;
  if (directionProp) {
    direction = {
      ...(directionProp === 'center'
        ? {
            marginLeft: 'auto',
            marginRight: 'auto',
          }
        : {}),
      [directionProp === 'right' ? 'marginLeft' : 'marginRight']: 'auto',
    };
  }
  return {
    margin,
    ...direction,
  };
};

const UIGetMarginLeftRight = margin => {
  if (margin.constructor === Array) {
    if (margin.length === 1) return 2 * margin[0];
    return (
      ((margin[1] || 0) + (margin[3] || 0)) *
      (margin[3] || margin[3] === 0 ? 1 : 2)
    );
  }
  if (typeof margin === 'number') return margin * 2;
};

const calculateIconsForOverflow = (
  mainWrap,
  overflowList,
  visibleIcons,
  maxIconCountInWrap,
) => {
  const padding =
    getComputedStyle(mainWrap, null)
      .getPropertyValue('padding-left')
      .split('px')[0] * 2;
  const mainWrapRect = mainWrap.getBoundingClientRect();
  const titleRect = mainWrap.children[0].getBoundingClientRect();
  const overlapCount = [];
  const _overFlowList = overflowList.filter(e => !e.props['data-complete']);

  for (let i = 0; i <= visibleIcons.length; i++) {
    if (
      mainWrapRect.width - padding < titleRect.width + 40 + i * 40 ||
      i >= maxIconCountInWrap
    ) {
      overlapCount.push(visibleIcons[i - 1].props['data-complete']);
      if (
        !_overFlowList.find(
          e =>
            e.props['data-complete'] ===
            visibleIcons[i - 1].props['data-complete'],
        )
      ) {
        _overFlowList.push(visibleIcons[i - 1]);
      }
    }
  }
  const _flexibleIcons = [...visibleIcons];
  overlapCount.forEach(attr => {
    const findIndex = _flexibleIcons.findIndex(
      e => e.props['data-complete'] === attr,
    );
    if (findIndex > -1) _flexibleIcons.splice(findIndex, 1);
  });
  return {
    _flexibleIcons,
    _overFlowList,
  };
};

const generateRouterReplace = (router, obj) => {
  router.push(
    {
      pathname: router.pathname,
      query: {
        ...router.query,
        ...obj,
      },
    },
    undefined,
    { scroll: false, shallow: true },
  );
};

const generateStorePropertyValues = (query, mainList, queryName) => {
  const _routerProp =
    typeof query[queryName] === 'string'
      ? [query[queryName]]
      : query[queryName] || [];
  const _allPropList = [...mainList];
  return _routerProp.reduce((acc, item) => {
    const foundedItem = _allPropList.find(e => e.value === item);
    if (foundedItem) acc.push(foundedItem);
    return acc;
  }, []);
};

function getRadianAngle(degreeValue) {
  return (degreeValue * Math.PI) / 180;
}

function rotateSize(width, height, rotation) {
  const rotRad = getRadianAngle(rotation);

  return {
    width:
      Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height:
      Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
  };
}

const createImage = url =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.src = url;
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', error => reject(error));
    image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
  });

async function getCroppedImg(imageSrc, pixelCrop, rotation = 0) {
  const image = await createImage(imageSrc);

  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  const iosVersion = checkIOSVersion();

  if (iosVersion && iosVersion < 18) {
    const rotRad = getRadianAngle(rotation);

    const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
      image.width,
      image.height,
      rotation,
    );

    canvas.width = bBoxWidth;
    canvas.height = bBoxHeight;

    ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
    ctx.rotate(rotRad);
    ctx.translate(-image.width / 2, -image.height / 2);

    ctx.drawImage(image, 0, 0);

    const data = ctx.getImageData(
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
    );

    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    ctx.putImageData(data, 0, 0);
  } else {
    const maxSize = Math.max(image.width, image.height);
    const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

    // set each dimensions to double largest dimension to allow for a safe area for the
    // image to rotate in without being clipped by canvas context
    canvas.width = safeArea;
    canvas.height = safeArea;

    // translate canvas context to a central location on image to allow rotating around the center.
    ctx.translate(safeArea / 2, safeArea / 2);
    ctx.rotate(getRadianAngle(rotation));
    ctx.translate(-safeArea / 2, -safeArea / 2);

    // draw rotated image and store data.
    ctx.drawImage(
      image,
      safeArea / 2 - image.width * 0.5,
      safeArea / 2 - image.height * 0.5,
    );
    const data = ctx.getImageData(0, 0, safeArea, safeArea);

    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    // paste generated rotate image with correct offsets for x,y crop values.
    ctx.putImageData(
      data,
      Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
      Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y),
    );
  }

  return new Promise(resolve => {
    canvas.toBlob(file => {
      resolve(URL.createObjectURL(file));
    }, 'image/jpeg');
  });
}

const fitToCanvasSize = async ({ w, h, imgSrc }) =>
  new Promise(resolve => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.width = w;
    canvas.height = h;
    const image = new Image();

    image.onload = () => {
      ctx.drawImage(image, 0, 0, w, h);
      canvas.toBlob(file => {
        resolve(URL.createObjectURL(file));
      }, 'image/jpeg');
    };
    image.src = imgSrc;
  });

const adjustDays = (yearValue, monthValue, setState) => {
  const year = yearValue;
  const month = parseInt(monthValue);
  const days = new Date(year, month, 0).getDate();
  setState(days || 31);
};

const SliderModule = (() => {
  let xDown = null;
  let yDown = null;

  function getTouches(evt) {
    return (
      evt.touches || // browser API
      evt.originalEvent.touches
    ); // jQuery
  }

  function handleTouchStart(evt) {
    const firstTouch = getTouches(evt)[0];
    xDown = firstTouch.clientX;
    yDown = firstTouch.clientY;
  }

  function handleTouchMove(evt, cb) {
    if (!xDown || !yDown) {
      return;
    }

    const xUp = evt.touches[0].clientX;
    const yUp = evt.touches[0].clientY;

    const xDiff = xDown - xUp;
    const yDiff = yDown - yUp;
    if (Math.abs(xDiff) > Math.abs(yDiff)) {
      /* most significant */
      if (xDiff > 0) {
        cb('LEFT');
      } else {
        cb('RIGHT');
      }
    } else if (yDiff > 0) {
      cb('TOP');
    } else {
      cb('DOWN');
    }
    /* reset values */
    xDown = null;
    yDown = null;
  }
  return {
    handleTouchStart,
    handleTouchMove,
  };
})();

function isTouchDevice() {
  try {
    const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');

    const mq = function (query) {
      return window.matchMedia(query).matches;
    };

    if (
      'ontouchstart' in window ||
      (typeof window.DocumentTouch !== 'undefined' &&
        document instanceof window.DocumentTouch)
    ) {
      return true;
    }

    return mq(['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join(''));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('(Touch detect failed)', e);
    return false;
  }
}

const Cookie = {
  setToken(token, expires = 3) {
    cookie.set('token', token, { ...(expires && { expires /* 3 day */ }) });
  },
  setAccountId(id) {
    cookie.set('accountId', id);
  },
  setLogByadmin() {
    cookie.set('logByAdmin', 'true');
  },
  get getLogByadmin() {
    return cookie.get('logByAdmin');
  },
  get getToken() {
    return cookie.get('token');
  },
  get getAccountId() {
    return cookie.get('accountId');
  },
  getTokenByReq(cookies) {
    return CookieParser.parse(cookies)?.token;
  },
  getCookieByKey(key) {
    return cookie.get(key);
  },
  removeCookieByKey(key) {
    if (key === 'token') {
      removeFromStorage('create-ad');
    }
    cookie.remove(key);
  },
  setCookieByKey(key, value, expires) {
    if (getApprovedCookie(key)) {
      cookie.set(key, value, { ...(expires && { expires }) });

      return true;
    }

    return false;
  },
};

const ifFormIsValid = (keys, formValues, errors) =>
  keys.every(
    e => formValues[e] && formValues[e] !== '' && !errors.hasOwnProperty(e),
  );

const generateOption = (list, titleType = 'capitalize') =>
  list.map(e => ({
    title:
      titleType === 'capitalize'
        ? e
            .split(' ')
            .map(
              _word => _word.charAt(0).toUpperCase() + _word.slice(1, e.length),
            )
            .join(' ')
        : titleType === 'uppercase'
        ? e.toUpperCase()
        : titleType === 'only-first'
        ? e.charAt(0).toUpperCase() + e.slice(1, e.length)
        : e,
    value: e,
  }));

function overridePropValue(data, withoutNestedValueProps = []) {
  const whitelist = ['languages', 'opening_hours']; // all props which types are arrayed
  if (data._changes) {
    const changes = data._changes.fields;
    Object.keys(changes).forEach(e => {
      if (whitelist.indexOf(e) !== -1) {
        data[e] = changes[e].map(l =>
          withoutNestedValueProps.indexOf(e) > -1 ? l.value : l.value.value,
        );
      } else {
        const current = changes[e][0];
        if (current) {
          data[e] =
            withoutNestedValueProps.indexOf(e) > -1
              ? current.value
              : current.value.value;
        }
      }
    });
  }
}

const mergeFields = (data, withoutNestedValue = []) => {
  if (Array.isArray(data)) {
    data.map(e => overridePropValue(e, withoutNestedValue));
  } else if (typeof data === 'object' && data !== null) {
    overridePropValue(data, withoutNestedValue);
  }
  return data;
};

const convertCapitalize = (str = '', uppercase) => {
  if (typeof str !== 'string') return str;

  if (uppercase) return str?.toUpperCase();

  return str
    ?.split(' ')
    .map(_word => _word.charAt(0).toUpperCase() + _word.slice(1, str.length))
    .join(' ');
};

const scrollToTop = offsetTop => {
  setTimeout(() => {
    window.scrollTo({
      top: offsetTop || 0,
      left: 0,
      behavior: 'smooth',
    });
  }, 100);
};

const generateCountry = (data = []) =>
  data.map(e => ({
    title: e.toUpperCase(),
    value: e,
    imgPath: `/images/flags/${e}.png`,
  }));

const ToggleScrolling = (() => {
  if (typeof window === 'undefined') return;

  const _keys = {
    37: 1,
    38: 1,
    39: 1,
    40: 1,
  };

  function _preventDefault(e) {
    e.preventDefault();
  }

  function _preventDefaultForScrollKeys(e) {
    if (_keys[e.keyCode]) {
      _preventDefault(e);
      return false;
    }
  }

  // modern Chrome requires { passive: false } when adding event
  let _supportsPassive = false;
  try {
    window.addEventListener(
      'test',
      null,
      Object.defineProperty({}, 'passive', {
        get() {
          _supportsPassive = true;
        },
      }),
    );
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }

  const _wheelOpt = _supportsPassive ? { passive: false } : false;
  const _wheelEvent =
    'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';

  // call this to Disable
  function disableScroll() {
    window.addEventListener('DOMMouseScroll', _preventDefault, false); // older FF
    window.addEventListener(_wheelEvent, _preventDefault, _wheelOpt); // modern desktop
    window.addEventListener('touchmove', _preventDefault, _wheelOpt); // mobile
    window.addEventListener('keydown', _preventDefaultForScrollKeys, false);
  }

  function enableScroll() {
    window.removeEventListener('DOMMouseScroll', _preventDefault, false);
    window.removeEventListener(_wheelEvent, _preventDefault, _wheelOpt);
    window.removeEventListener('touchmove', _preventDefault, _wheelOpt);
    window.removeEventListener('keydown', _preventDefaultForScrollKeys, false);
  }

  return {
    disableScroll,
    enableScroll,
  };
})();

const RAFScrollTo = (() => {
  if (typeof window === 'undefined') return;

  window.requestAnimFrame = (function () {
    return (
      window.requestAnimationFrame ||
      window.webkitRequestAnimationFrame ||
      window.mozRequestAnimationFrame ||
      function (callback) {
        window.setTimeout(callback, 1000 / 60);
      }
    );
  })();

  function goToY(
    scrollTargetY = 0,
    speed = 400,
    easing = 'easeOutSine',
    defaultScrollY,
    view = window,
    cb,
  ) {
    const scrollY = defaultScrollY;
    let currentTime = 0;

    const time = Math.max(
      0.1,
      Math.min(Math.abs(scrollY - scrollTargetY) / speed, 0.8),
    );

    const easingEquations = {
      easeOutSine(pos) {
        return Math.sin(pos * (Math.PI / 2));
      },
      easeInOutSine(pos) {
        return -0.5 * (Math.cos(Math.PI * pos) - 1);
      },
      easeInOutQuint(pos) {
        if ((pos /= 0.5) < 1) {
          return 0.5 * pos ** 5;
        }
        return 0.5 * ((pos - 2) ** 5 + 2);
      },
    };

    // add animation loop
    function tick() {
      currentTime += 1 / 60;

      const p = currentTime / time;
      const t = easingEquations[easing](p);

      if (p < 1) {
        window.requestAnimFrame(tick);
        view.scrollTo(0, scrollY + (scrollTargetY - scrollY) * t);
      } else {
        view.scrollTo(0, scrollTargetY);
        ToggleScrolling.enableScroll();
        cb?.();
      }
    }

    // call it once to get started
    tick();
  }

  return {
    goToY,
  };
})();

const createAdsFilterObject = item => ({
  title: item.title,
  value: item._id,
  checked: item.checked,
});

const extractURL = str => {
  const patterns = ['https://', 'https://', 'http://', 'http://'];
  for (let i = 0, len = patterns.length; i < len; i++) {
    const pattern = patterns[i];
    if (str.indexOf(pattern) === 0) return str.substring(pattern.length);
  }
  return str;
};

const generateMediaURL = ({
  created_by,
  purpose,
  ext,
  hash,
  thumb = 'base_thumb',
}) => {
  const cache = new Date().getTime();
  return `${MEDIA.storagePath}/${created_by}/${purpose}/${hash}_${thumb}.${ext}?cache=${cache}`;
};

const generateColor = (() => {
  const pad = function (num, totalChars) {
    const pad = '0';
    num = `${num}`;
    while (num.length < totalChars) {
      num = pad + num;
    }
    return num;
  };

  // Ratio is between 0 and 1
  const changeColor = function (color, ratio, darker) {
    // Trim trailing/leading whitespace
    color = color.replace(/^\s*|\s*$/, '');

    // Expand three-digit hex
    color = color.replace(
      /^#?([a-f0-9])([a-f0-9])([a-f0-9])$/i,
      '#$1$1$2$2$3$3',
    );

    // Calculate ratio
    const difference = Math.round(ratio * 256) * (darker ? -1 : 1);
    // Determine if input is RGB(A)
    const rgb = color.match(
      new RegExp(
        '^rgba?\\(\\s*' +
          '(\\d|[1-9]\\d|1\\d{2}|2[0-4][0-9]|25[0-5])' +
          '\\s*,\\s*' +
          '(\\d|[1-9]\\d|1\\d{2}|2[0-4][0-9]|25[0-5])' +
          '\\s*,\\s*' +
          '(\\d|[1-9]\\d|1\\d{2}|2[0-4][0-9]|25[0-5])' +
          '(?:\\s*,\\s*' +
          '(0|1|0?\\.\\d+))?' +
          '\\s*\\)$',
        'i',
      ),
    );
    const alpha = !!rgb && rgb[4] != null ? rgb[4] : null;

    // Convert hex to decimal
    const decimal = rgb
      ? [rgb[1], rgb[2], rgb[3]]
      : color
          .replace(
            /^#?([a-f0-9][a-f0-9])([a-f0-9][a-f0-9])([a-f0-9][a-f0-9])/i,
            function () {
              return `${parseInt(arguments[1], 16)},${parseInt(
                arguments[2],
                16,
              )},${parseInt(arguments[3], 16)}`;
            },
          )
          .split(/,/);

    // Return RGB(A)
    return rgb
      ? `rgb${alpha !== null ? 'a' : ''}(${Math[darker ? 'max' : 'min'](
          parseInt(decimal[0], 10) + difference,
          darker ? 0 : 255,
        )}, ${Math[darker ? 'max' : 'min'](
          parseInt(decimal[1], 10) + difference,
          darker ? 0 : 255,
        )}, ${Math[darker ? 'max' : 'min'](
          parseInt(decimal[2], 10) + difference,
          darker ? 0 : 255,
        )}${alpha !== null ? `, ${alpha}` : ''})`
      : // Return hex
        [
          '#',
          pad(
            Math[darker ? 'max' : 'min'](
              parseInt(decimal[0], 10) + difference,
              darker ? 0 : 255,
            ).toString(16),
            2,
          ),
          pad(
            Math[darker ? 'max' : 'min'](
              parseInt(decimal[1], 10) + difference,
              darker ? 0 : 255,
            ).toString(16),
            2,
          ),
          pad(
            Math[darker ? 'max' : 'min'](
              parseInt(decimal[2], 10) + difference,
              darker ? 0 : 255,
            ).toString(16),
            2,
          ),
        ].join('');
  };

  const lighterColor = function (color, ratio) {
    return changeColor(color, ratio, false);
  };
  const darkerColor = function (color, ratio) {
    return changeColor(color, ratio, true);
  };

  return {
    lighterColor,
    darkerColor,
  };
})();

const unreadCountAsBadge = (
  count,
  maxCount = 9,
  className = 'count--block',
) => {
  if (!count) return null;
  return (
    <span style={{ marginLeft: '5px' }} className={className}>
      {count > maxCount ? `${maxCount}+` : count}
    </span>
  );
};

const getCurrentDataFromStorage = (data, adCategoryType) => {
  const {
    category = '',
    title = '',
    description = '',
    city = '',
    phone_instruction = '',
    phone_numbers = [],
    websites = [],
    availability = [],
    location_types = [],
    possibilities = [],
    prices = [],
    working_hours = [],
    salary = {},
    event_type = null,
    work_type = null,
    employment_type = null,
    start_date = formatISO(new Date()),
    end_date = formatISO(new Date()),
  } = data;
  const mergeData = {
    category,
    type: adCategoryType,
    title,
    description,
    city,
    phone_instruction,
    phone_numbers: phone_numbers
      .filter(e => e.selected)
      .map(e => ({
        number: `${e.number}`, // convert to string
        country_code: `${e.country_code}`, // convert to string
      })),
    websites: websites
      .filter(e => e.selected)
      .map(e => ({
        value: e.value,
      })),
  };
  switch (adCategoryType) {
    case 'regular':
      return {
        ...mergeData,
        services: possibilities.filter(e => e.checked).map(e => e.value),
        working_hours: availability.map(e => ({
          week_day: e.value,
          from: e.time.start.value,
          to: e.time.end.value,
        })),
        prices: prices.map(e => ({
          id: e.id,
          time: e.duration,
          type: e.durationType,
          price: e.price,
          currency: e.currency,
        })),
        location_types: location_types.filter(e => e.checked).map(e => e.value),
      };
    case 'event':
      return {
        ...mergeData,
        start_date,
        end_date,
        event_type,
        prices: prices.map(e => ({
          id: e.id,
          time: e.duration,
          type: e.durationType,
          price: e.price,
          currency: e.currency,
        })),
      };
    case 'vacancy':
      return {
        ...mergeData,
        work_type,
        employment_type,
        vacancy_working_type: working_hours.map(e => ({
          value: e.value,
        })),
        vacancy_salary: salary,
      };
    default:
      return {};
  }
};

const getShowName = (profile, sliceCount) => {
  const currentName =
    profile?.showname || profile?._changes.fields?.showname?.[0]?.value?.value;

  const name = currentName?.slice(0, sliceCount || currentName.length);

  return name?.length < currentName?.length ? `${name}...` : name;
};

export const logout = (timeout = 0, url = '/login') => {
  removeFromStorage('refreshToken');
  Cookie.removeCookieByKey('token');
  sessionStorage.clear();

  setTimeout(() => {
    window.location.href = url;
  }, timeout);
};

export {
  getShowName,
  extractURL,
  UiGenerateMargin,
  UIGetMarginLeftRight,
  generateStorePropertyValues,
  generateRouterReplace,
  getCroppedImg,
  calculateIconsForOverflow,
  adjustDays,
  SliderModule,
  isTouchDevice,
  fitToCanvasSize,
  Cookie,
  ifFormIsValid,
  generateOption,
  generateCountry,
  mergeFields,
  convertCapitalize,
  scrollToTop,
  ToggleScrolling,
  createAdsFilterObject,
  generateMediaURL,
  generateColor,
  RAFScrollTo,
  unreadCountAsBadge,
  getCurrentDataFromStorage,
};
