import { useState, useRef, useCallback, memo, useMemo } from 'react';
import Dialog from '@material-ui/core/Dialog';
import useMount from 'hooks/use-mount';
import useMemoSelector from 'hooks/useMemoSelector';

import { Cookie } from 'utils/handlers';
import { saveToStorage, getFromStorage } from 'utils/persist';

import { getLanguage } from 'store/reselect';

import PrivacyStatement from './PrivacyStatement';
import LegalInformation from './LegalInformation';
import CustomizeCookies from './CustomizeCookies';
import TermsAndConditions from './TermsAndConditions';

function removeAllCookies() {
  const res = document.cookie;
  const multiple = res.split('; ');

  multiple.forEach(cookie => {
    const [key] = cookie.split('=');
    Cookie.removeCookieByKey(key);
  });
}

const legalInformationSections = {
  CustomizeCookies,
  TermsAndConditions,
  PrivacyStatement,
  LegalInformation,
};

const defaultCookies = {
  necessary: true,
  functional: true,
  marketing: true,
};

const defaultAccess = {
  legalAge: false,
  privacy: false,
  cookies: false,
};

function TermsAndPrivacy() {
  const cookiesRef = useRef();
  const [open, setOpen] = useState(false);
  const [cookiesToggle, setCookiesToggle] = useState(defaultAccess);
  const [page, setPage] = useState('LegalInformation');
  const [customizeCookies, setCustomizeCoolies] = useState(defaultCookies);
  const lang = useMemoSelector(getLanguage);

  useMount(() => {
    if (!getFromStorage('acceptTermsAndPrivacy')) {
      const timerId = setTimeout(() => {
        const cookies = document.cookie.split('; ');
        cookiesRef.current = cookies;

        removeAllCookies();
        setOpen(true);
      }, 1000);

      return () => clearTimeout(timerId);
    }
  });

  const handleClose = useCallback(
    (_, reason) => {
      if (reason === 'backdropClick') {
        return false;
      }

      cookiesRef.current.forEach(cookie => {
        if (cookie.includes('null') || cookie.includes('undefined')) return;
        const [key, value] = cookie.split('=');

        Cookie.setCookieByKey(key, value);
      });

      saveToStorage('acceptTermsAndPrivacy', true);
      saveToStorage('acceptedCookies', customizeCookies);
      Cookie.setCookieByKey('lang', lang);
      setOpen(false);
    },
    [customizeCookies, lang],
  );

  const handleGoBack = useCallback(() => setPage('LegalInformation'), []);

  const Component = legalInformationSections[page];

  const pagesProps = useMemo(
    () => ({
      CustomizeCookies: {
        customizeCookies,
        setCustomizeCoolies,
      },
      LegalInformation: {
        cookiesToggle,
        setCookiesToggle,
      },
    }),
    [cookiesToggle, customizeCookies],
  );

  return (
    <Dialog
      style={{ zIndex: 999999 }}
      fullWidth
      open={open}
      disableEscapeKeyDown
      onClose={handleClose}
    >
      <Component
        setPage={setPage}
        handleClose={handleClose}
        handleGoBack={handleGoBack}
        {...(pagesProps[page] || {})}
      />
    </Dialog>
  );
}

export default memo(TermsAndPrivacy);
