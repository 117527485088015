import { memo } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { useDialogStyles } from '../makeStylesUI';

import useStyles from './styles';

const SimpleDialog = props => {
  const styles = useStyles();
  const classesDialog = useDialogStyles({
    minWidth: props.minWidth ? `${props.minWidth}px` : 'auto',
    width: props.width ? props.width + +'px' : 'auto',
  });

  return (
    <Dialog
      onExited={props.closeAnimationEnd}
      className={props.className || ''}
      classes={classesDialog}
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={props.fullWidth}
      maxWidth={props.maxWidth}
      fullScreen={props.fullScreen}
    >
      {props.title ? (
        <DialogTitle
          style={props.titleColor && { color: props.titleColor }}
          id="alert-dialog-title"
        >
          <div className={clsx({ flex: props.withCloseButton })}>
            <div>{props.title}</div>
            {props.withCloseButton && (
              <img
                style={{ cursor: ['pointer'] }}
                src="/images/icons/close.svg"
                onClick={props.onClose}
              ></img>
            )}
          </div>
        </DialogTitle>
      ) : null}
      <DialogContent style={{ minWidth: props.minWidth }}>
        {props.children}
      </DialogContent>
      {props.actions ? (
        <DialogActions
          data-vertical={props.actionsVertical}
          className={styles['btn-actions-line--block']}
        >
          {props.actions.cancel ? (
            <button onClick={props.onClose}>{props.actions.cancel}</button>
          ) : null}
          {props.actions.ok ? (
            <button onClick={props.onOk} disabled={props.disableOkBtn}>
              {props.actions.ok}
            </button>
          ) : null}
          {props.actions.verify ? (
            <button onClick={props.onVerify} disabled={props.disableVerifyBtn}>
              {props.actions.verify}
            </button>
          ) : null}
        </DialogActions>
      ) : null}
    </Dialog>
  );
};

SimpleDialog.defaultProps = {
  open: false,
  fullWidth: false,
  maxWidth: 'xs',
  actionsVertical: false,
  disableOkBtn: false,
  minWidth: 200,
};
SimpleDialog.propTypes = {
  actionsVertical: PropTypes.bool,
  open: PropTypes.bool,
  disableOkBtn: PropTypes.bool,
  maxWidth: PropTypes.string,
  minWidth: PropTypes.number,
  width: PropTypes.number,
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
  onClose: PropTypes.func,
  onOk: PropTypes.func,
  closeAnimationEnd: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  actions: PropTypes.shape({
    cancel: PropTypes.string,
    ok: PropTypes.string,
  }),
};
export default memo(SimpleDialog);
